* {
  font-family: "Raleway", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  font-family: "Raleway", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  background-color: #070707;
}

.page {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;
}
.App {
  color: white;
}
.activelink {
  font-weight: 1000;
}

.swiper_container {
  height: auto;
  position: relative;
}

.swiper-slide {
  width: 80%;
  position: relative;
}



.swiper-slide img {
  width: 100%;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controller {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next {
  right: 40% !important;
  position: relative;

}
.swiper-button-prev {
  left: 40% !important;
  position: relative;

}

@media screen and (max-width: 480px) {
  .swiper-button-next {
    right: 20%  !important;
  }
  .swiper-button-prev {
    left: 20%  !important;
  }
}


.slider-controller .slider-arrow {
bottom: 1rem; 
}

.slider-controller .slider-arrow svg {
  font-size: 16px;
  color: white;
}

.slider-controller .slider-arrow::after {
  content: "";
}

.swiper-pagination {
  position: relative;
  width: 100% !important;
}

.swiper-pagination .swiper-pagination-bullet {
  background-color: white;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: #069fce;
}
